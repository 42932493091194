import { countries, imageType, propertyTypes } from '@/domain/utils/constants';
import { NonEmptyStringInList } from '../abstract/NonEmptyStringInList';

export class HttpMethod {
  public static POST = 'POST' as const;
  public static GET = 'GET' as const;
  public static DELETE = 'DELETE' as const;
  public static PUT = 'PUT' as const;
  public static PATCH = 'PATCH' as const;
}

export class Country extends NonEmptyStringInList<CountryType> {
  get name(): string {
    return countries[this.value].name;
  }

  get flag(): string {
    return countries[this.value].flag;
  }
}

export type PropertyType = keyof typeof propertyTypes;

export type DatabaseImageType = keyof typeof imageType;

export type CountryType = keyof typeof countries;
