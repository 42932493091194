import { useSelector } from 'react-redux';
import { ReactNode, useEffect } from 'react';
import { App } from 'antd';
import { actions, selectors } from '@/infrastructure/ui/store/slices/ui';
import { NOTIFICATION_LOCATION } from '@/infrastructure/ui/lib/constants';
import { useAppDispatch } from '@/infrastructure/ui/store';

interface ExceptionNotificatorProps {
  children: ReactNode
}

export function ExceptionNotificator({ children }: ExceptionNotificatorProps) {
  const exception = useSelector(selectors.exception);
  const dispatch = useAppDispatch();
  const { notification } = App.useApp();

  useEffect(() => {
    if (exception?.message) {
      notification.error({
        message: 'Error',
        description: exception.message,
        placement: NOTIFICATION_LOCATION,
        onClose: () => dispatch(actions.setException()),
      });
    }
  }, [exception?.message]);

  return children;
}
