export const getItem = (key: string) => {
  if (typeof window !== 'undefined') {
    if (typeof localStorage.getItem(key) === 'string') {
      return localStorage.getItem(key) as string;
    }
  }
};

export const getJsonItem = (key: string) => {
  if (typeof window !== 'undefined') {
    if (typeof localStorage.getItem(key) === 'string') {
      const item = localStorage.getItem(key) === '' ? '{}' : localStorage.getItem(key);
      return JSON.parse(item ?? '{}');
    }
  }
};

export const setItem = (key: string, value: string) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value);
  }
};

export const removeItem = (key: string) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};
