export const countries = {
  aw: {
    code: 'aw',
    name: 'Aruba',
    flag: '🇦🇼',
  },
  ve: {
    code: 've',
    name: 'Venezuela',
    flag: '🇻🇪',
  },
} as const;

export const propertyTypes = {
  smart: 'smart',
  opal: 'opal',
} as const;

export const imageType = {
  ...propertyTypes,
  banner: 'banner',
};
