/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import { AppProps } from 'next/app';
import NextHead from 'next/head';
import { IntlProvider } from 'react-intl';

import React from 'react';
import Document, {
  Html,
  Head,
  Main,
  NextScript,
} from 'next/document';
import type { DocumentContext } from 'next/document';
import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs';
import { Provider } from 'react-redux';
import messagesEn from '@/infrastructure/ui/translations/en.json';
import { getItem } from '@/infrastructure/ui/lib/ls';
import { store } from '@/infrastructure/ui/store';
import { AntdRegistry } from '@/infrastructure/ui/components/AntdRegistry';
import { ExceptionNotificator } from '@/infrastructure/ui/components/ExceptionNotificator';

const messages = {
  es: messagesEn,
} as const;

const language = (getItem('language') ?? 'es') as keyof typeof messages;

export function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <NextHead>
        <title>Spring Group</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="shortcut icon"
          href="/logo.jpeg"
        />
      </NextHead>
      <IntlProvider locale={language} messages={messages[language]}>
        <Provider store={store}>
          <AntdRegistry>
            <ExceptionNotificator>
              <Component {...pageProps} />
            </ExceptionNotificator>
          </AntdRegistry>
        </Provider>
      </IntlProvider>
    </>
  );
}

export function MyDocument() {
  return (
    <Html lang="en">
      <Head />
      <body>
        <Main />
        <NextScript />
      </body>
    </Html>
  );
}

MyDocument.getInitialProps = async (ctx: DocumentContext) => {
  const cache = createCache();
  const originalRenderPage = ctx.renderPage;
  ctx.renderPage = () => originalRenderPage({
    enhanceApp: (EnhanceApp) => (props) => (
      <StyleProvider cache={cache}>
        <EnhanceApp {...props} />
      </StyleProvider>
    ),
  });

  const initialProps = await Document.getInitialProps(ctx);
  const style = extractStyle(cache, true);
  return {
    ...initialProps,
    styles: (
      <>
        {initialProps.styles}
        <style dangerouslySetInnerHTML={{ __html: style }} />
      </>
    ),
  };
};
