/* eslint-disable react/no-danger */

import { ReactNode, useState } from 'react';
import { useServerInsertedHTML } from 'next/navigation';
import { StyleProvider, createCache, extractStyle } from '@ant-design/cssinjs';
import { ConfigProvider, App } from 'antd';
import en_US from 'antd/locale/en_US';

export function AntdRegistry({ children }: { children: ReactNode }) {
  const [cache] = useState(() => createCache());

  useServerInsertedHTML(() => (
    <style id="antd" dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }} />
  ));

  return (
    <StyleProvider cache={cache} hashPriority="high">
      <ConfigProvider
        locale={en_US}
        theme={{
          token: {
            fontFamily: '',
            fontSize: 16,
          },
        }}
      >
        <App>
          {children}
        </App>
      </ConfigProvider>
    </StyleProvider>
  );
}
