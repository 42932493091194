/* eslint-disable max-len */
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import { camelizeKeys } from 'humps';
import { actions as uiActions } from '@/infrastructure/ui/store/slices/ui';
import { Exception } from '@/domain/model/interfaces/Exception';
import { ExtraOptions } from '@/domain/model/interfaces/ExtraOptions';

const HEADERS = {
  contentType: 'content-type',
  applicationJson: 'application/json',
} as const;

export const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, store, opts: ExtraOptions = {}) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: (headers) => {
      if (!opts.isMultipart) {
        headers.set(HEADERS.contentType, HEADERS.applicationJson);
      }

      return headers;
    },
    responseHandler: async (response) => {
      let data = null;
      const contentType = response.headers.get(HEADERS.contentType) ?? '';

      if (contentType.includes(HEADERS.applicationJson)) {
        const text = await response.text();
        data = text.length ? camelizeKeys(JSON.parse(text)) : {};
      } else if (/\b(?:|audio|image|csv)\b/.test(contentType)) {
        data = await response.blob();
      } else {
        data = await response.text();
      }

      const final = { error: !response.ok, data };

      if (!response.ok && opts.handleServerError) {
        store.dispatch(uiActions.setException(data as Exception));
      }

      return final;
    },
  });

  return rawBaseQuery(args, store, opts);
};
