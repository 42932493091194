import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/infrastructure/ui/lib/http';
import { APIResponse } from '@/domain/model/interfaces/APIResponse';
import { BookingDTO } from '@/domain/model/entities/Booking';
import { HttpMethod } from '@/domain/model/common';

export const {
  useBookingMutation: useBooking,
  reducer,
  reducerPath,
  middleware,
} = createApi({
  reducerPath: 'booking__api',
  baseQuery,
  endpoints: (builder) => ({
    booking: builder.mutation<APIResponse<boolean>, BookingDTO>({
      query: (body) => ({
        url: '/api/booking',
        method: HttpMethod.POST,
        body,
      }),
      extraOptions: {
        handleServerError: true,
        multipart: false,
      },
    }),
  }),
});
