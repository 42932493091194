import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '@/infrastructure/ui/store';
import { Exception } from '@/domain/model/interfaces/Exception';

interface UIState {
  exception?: Exception
}

const initialState: UIState = {};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setException(state, action: PayloadAction<Exception | undefined>) {
      state.exception = action.payload;
    },
  },
});

export const selectors = {
  exception: ({ ui }: RootState) => ui.exception,
};

export const {
  actions,
  reducer,
  reducerPath,
} = slice;
