import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  reducer as uiReducer,
  reducerPath as uiReducerPath,
} from '@/infrastructure/ui/store/slices/ui';
import {
  reducerPath as bookingReducerPath,
  reducer as bookingReducer,
  middleware as bookingMiddleware,
} from '@/infrastructure/ui/services/booking';
import {
  reducerPath as contactReducerPath,
  reducer as contactReducer,
  middleware as contactMiddleware,
} from '@/infrastructure/ui/services/contact';

export const store = configureStore({
  reducer: {
    [uiReducerPath]: uiReducer,
    [bookingReducerPath]: bookingReducer,
    [contactReducerPath]: contactReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(
      bookingMiddleware,
      contactMiddleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
